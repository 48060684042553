<template>
	<ion-page id="newsdetail">
		<ion-header :translucent="true">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-back-button
						text="Zurück"
						default-href="/"
					></ion-back-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="inset-container" :fullscreen="true">
			<div class="postwrap">
				<div class="image ion-padding-horizontal">
					<img :src="imgLink" class="" />
					<div
						class="copyright"
						v-if="news.bild && news.bild.copyright"
					>
						© {{ news.bild.copyright }}
					</div>
				</div>
				<div class="content ion-padding">
					<span
						v-if="news.kategorie"
						:class="{
							advertorial: news.kategorie.name === 'Advertorial',
						}"
						>{{ news.kategorie.name.toUpperCase() }}</span
					>
					<h1 class="slabs">{{ news.title }}</h1>
					<ion-text v-html="news.artikel"></ion-text>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import {
	IonButtons,
	IonContent,
	IonHeader,
	IonBackButton,
	IonPage,
	IonText,
	IonToolbar,
} from '@ionic/vue';

export default {
	name: 'News',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonBackButton,
		IonPage,
		IonText,
		IonToolbar,
	},
	data() {
		return {
			news: {},
		};
	},
	computed: {
		imgLink() {
			if (!this.news.bild) return;
			return `${this.$store.state.imageBase}${
				this.news.bild.id
			}?key=newscardlg&access_token=${localStorage.getItem(
				'auth_token'
			)}`;
		},
	},
	methods: {
		async ionViewWillEnter() {
			const { data } = await this.$http.get(
				`/items/news/${this.$route.params.id}?fields=*,bild.*,kategorie.*`
			);
			console.log(data.data);
			this.news = data.data;
		},
	},
};
</script>

<style lang="scss" scoped>
#newsdetail {
	.postwrap {
		max-width: 960px;
		margin: 0 auto;
		background-color: var(--ion-color-light);
	}

	.image {
		position: relative;
	}
	.copyright {
		position: absolute;
		bottom: 4px;
		left: 16px;
		font-size: 12px;
		padding: 0.15em 0.75em;
		background: rgba(0, 0, 0, 0.4);
		color: #ffffff;
	}
	.content {
		h1 {
			margin-top: 5px;
			font-size: 24px;
		}
		span {
			color: var(--ion-color-dark);
			font-size: 12px;
		}
		.advertorial {
			background-color: var(--ion-color-tertiary);
			display: inline !important;
			padding-left: 2px;
			padding-right: 2px;
		}
	}
}
</style>
